<template>
  <div>
    <header-slot> 
      <template #actions>
        <portal-target name="createButtonMotive"></portal-target>
      </template>
    </header-slot>
    
    <b-card
      no-body
    >
      <router-view />
    </b-card>
  </div>
</template>
<script>
export default {};
</script>

<style></style>
