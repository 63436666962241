<template>
  <div>
    <header-slot> </header-slot>
    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view />
    </b-card>
  </div>
</template>
<script>
export default {};
</script>

<style></style>
